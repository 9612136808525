import * as React from "react";

import { useSignUp } from "@clerk/remix";
import { getAuth } from "@clerk/remix/ssr.server";
import { parseWithZod } from "@conform-to/zod";
import {
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  json,
  redirect,
} from "@remix-run/node";
import { useFetcher, useNavigate } from "@remix-run/react";
import { Text } from "~/components/ui/text";
import {
  PhoneNumberNotUnique,
  registerUser,
  UserExists,
} from "~/services/users/onboarding.server";
import { safeRoutes } from "~/utils/routes";
import type { SignUpDTO } from "./components/signUpForm";
import { SignUpError, SignUpForm, signUpSchema } from "./components/signUpForm";

import { businessGuard } from "../business+/business-guard.server";

export async function loader(args: LoaderFunctionArgs) {
  const url = new URL(args.request.url);

  if (safeRoutes.auth.signUp === url.pathname) {
    throw redirect(safeRoutes.home.index);
  }

  const { sessionId } = await getAuth(args);

  if (sessionId) {
    const { currentBusiness } = await businessGuard(args);
    throw redirect(
      safeRoutes.business.home(currentBusiness?.slug ?? "onboarding"),
    );
  }

  return null;
}

export async function action({ request }: ActionFunctionArgs) {
  const url = new URL(request.url);
  const formData = await request.formData();
  const submission = parseWithZod(formData, { schema: signUpSchema });
  const referralCode = url.searchParams.get("referral_id") ?? undefined;

  try {
    if (submission.status !== "success") {
      throw new Error("Form validation failed.");
    }

    const { user } = await registerUser(submission.value, referralCode);

    return json({
      success: true,
      user,
    });
  } catch (e) {
    let error: any = {};

    if (e instanceof UserExists) {
      error.email = ["An account with this email already exists."];
    }

    if (e instanceof PhoneNumberNotUnique) {
      error.phone = [
        "This phone number is already associated with an account. Please use a different phone number or sign in with your existing account.",
      ];
    }

    return json({
      success: false,
      user: null,
      ...submission.reply(),
      error,
    });
  }
}

export default function SignUpPage() {
  const fetcher = useFetcher<typeof action>({ key: "sign-up" });
  const navigate = useNavigate();
  const { signUp } = useSignUp();

  React.useEffect(() => {
    /**
     * If the validation and answer from server is successful, we send the
     * verification email to the customer, and we redirect to the verify screen.
     */
    const startVerification = async () => {
      if (fetcher.data?.success && signUp) {
        try {
          // Send email code
          await signUp.prepareEmailAddressVerification({
            strategy: "email_code",
          });
          return navigate(safeRoutes.auth.signUpVerify);
        } catch (err: any) {
          if (err.clerkError) {
            throw new SignUpError("Problem sending verification email.", {
              email: err.errors.map((i: any) => i.message),
            });
          }
          console.error(err);
        }
      }
    };
    startVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher.data, signUp?.id]);

  /**
   * Starts custom SignUp flow using Clerk in the client side. It creates a cookie
   * that is unique to the sign up process and can be access by other Clerk
   * components to trigger validation/verification processes.
   */
  async function validateClerkEmail(email: string) {
    try {
      // Start the sign-up process using the email address
      await signUp?.create({
        emailAddress: email,
      });
    } catch (err: any) {
      // Forces Clerk errors to map to email field since is the strategy
      // we are using to check valid addresses and create the session
      // with Clerk.
      if (err.clerkError) {
        throw new SignUpError("Problem creating user.", {
          email: err.errors.map((i: any) => i.message),
        });
      }
      console.error(err);
    }
  }

  async function handleSubmit(signUpDTO: SignUpDTO) {
    try {
      // Since we are using fetcher.Form, we need to submit the form manually
      // after pre-validation with Clerk.
      fetcher.submit({ ...signUpDTO }, { method: "post" });
    } catch (err: any) {
      console.error(err);
    }
  }

  return (
    <>
      <div className="px-2">
        <Text variant="h1" color="brand" className="mt-[16px] text-center">
          Sign up for Hansa!
        </Text>
        <Text variant="p" color="muted" className="mt-4  p-[12px] text-center">
          Get the help you need to plan, fund, and grow your business.
        </Text>

        <Text variant="p" color="muted" className="mt-4 p-[12px] text-center">
          Returning user?{" "}
          <a className="underline" href={safeRoutes.auth.signIn}>
            Log in here
          </a>
        </Text>
      </div>
      <div className="h-fit w-full max-w-[1012px] p-6 mx-auto">
        <SignUpForm
          onSubmit={handleSubmit}
          validateClerkEmail={validateClerkEmail}
          fetcherErrors={(fetcher.data as any)?.error}
        />

        <div className="mt-8">
          <a
            href="https://www.withhansa.com/termsandconditions"
            target="_blank"
            className="text-gray-600 text-sm underline"
            rel="noreferrer"
          >
            terms & conditions
          </a>
        </div>
      </div>
    </>
  );
}
