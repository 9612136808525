import { getFormProps, useForm, useInputControl } from "@conform-to/react";
import { parseWithZod } from "@conform-to/zod";
import { useFetcher } from "@remix-run/react";
import { useEffect, useState } from "react";
import { z } from "zod";
import { Button } from "~/components/ui/buttons";
import type { CountryCode } from "libphonenumber-js";
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js";
import { LoadingSpinner } from "~/components/ui/loading-spinner";
import { type action } from "../signup";
import { EmailStep } from "./signUp/emailStep";
import { UserInfoStep } from "./signUp/userInfoStep";
import { Card } from "~/components/ui/card";
import { Text } from "~/components/ui/text";
import { cn } from "~/utils";

export const signUpSchema = z.object({
  email: z
    .string({ required_error: "Email is required" })
    .trim()
    .toLowerCase()
    .email("Email is invalid")
    .min(1),
  firstName: z.string({ required_error: "First Name is required" }).min(1),
  lastName: z.string({ required_error: "Last Name is required" }).min(1),
  phoneNumber: z.string().optional(),
  country: z.string({ required_error: "Country is required" }),
});

export type SignUpDTO = z.infer<typeof signUpSchema>;

export class SignUpError extends Error {
  public errors: any;
  constructor(message: string, errors: any) {
    super(message);
    this.name = "SignUpError";
    this.errors = errors;
  }
}

enum SignUpSteps {
  EMAIL = "EMAIL",
  USER_INFO = "USER_INFO",
  PASSWORD = "PASSWORD",
  PASSWORDLESS = "PASSWORDLESS",
}
export const SignUpForm = ({
  onSubmit,
  validateClerkEmail,
  fetcherErrors,
}: {
  onSubmit: (data: any) => Promise<void>;
  validateClerkEmail: (email: string) => Promise<void>;
  fetcherErrors: any;
}) => {
  const fetcher = useFetcher<typeof action>({ key: "sign-up" });
  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [signUpStep, setSignUpStep] = useState(SignUpSteps.EMAIL);

  const [form, fields] = useForm({
    shouldValidate: "onBlur",
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: signUpSchema });
    },
  });

  const country = useInputControl(fields.country);

  useEffect(() => {
    if (fetcherErrors && fetcherErrors.phone) {
      setErrors((prev: any) => ({ ...prev, phoneNumber: fetcherErrors.phone }));
    }
  }, [fetcherErrors]);

  async function handleNextStep() {
    if (signUpStep === SignUpSteps.EMAIL) {
      if (!fields.email.value) return;

      try {
        await validateClerkEmail(fields.email.value);
        setSignUpStep(SignUpSteps.USER_INFO);
      } catch (err: any) {
        setErrors(err.errors);
      }
    }
    if (signUpStep === SignUpSteps.USER_INFO) {
      if (
        !fields.firstName.value ||
        !fields.lastName.value ||
        !fields.country.value
      )
        return;

      let phone = null;
      const country = JSON.parse(fields.country.value);

      if (fields.phoneNumber.value) {
        setErrors({});
        phone = parsePhoneNumber(
          `${country.phone_code}${fields.phoneNumber.value}`,
          {
            defaultCountry: country.code as CountryCode,
          },
        );

        if (!phone) {
          setErrors({
            phoneNumber: ["Phone number is invalid"],
          });
          return;
        }

        const valid = isValidPhoneNumber(
          phone.number,
          country.code as CountryCode,
        );

        if (!valid) {
          setErrors({
            phoneNumber: ["Phone number is invalid"],
          });

          return;
        }
      }

      try {
        setIsSubmitting(true);
        await onSubmit({
          email: fields.email.value,
          firstName: fields.firstName.value,
          lastName: fields.lastName.value,
          country: country.name,
          ...(phone ? { phoneNumber: phone.number } : {}),
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  }
  return (
    <Card className="p-6  text-black">
      {signUpStep === SignUpSteps.USER_INFO && (
        <Text color="brand" variant="h2" className="text-left">
          User information
        </Text>
      )}
      <fetcher.Form method="post" {...getFormProps(form)}>
        <div className="flex flex-col gap-3">
          <div className="w-full flex flex-col md:flex-row gap-5">
            <div className="w-full bg-white border border-gray-300 rounded-md p-[12px]">
              <div
                className={cn("", {
                  hidden: signUpStep !== SignUpSteps.EMAIL,
                })}
              >
                <EmailStep fields={fields} errors={errors} />
              </div>

              <div
                className={cn("", {
                  hidden: signUpStep !== SignUpSteps.USER_INFO,
                })}
              >
                <UserInfoStep
                  country={country}
                  fields={fields}
                  errors={errors}
                />
              </div>
            </div>
          </div>
        </div>

        <Button
          type="button"
          onClick={handleNextStep}
          variant="primary"
          size="lg"
          className="mt-6 w-full lg:w-1/3 mx-auto flex items-center gap-4"
          disabled={isSubmitting}
        >
          Continue {isSubmitting && <LoadingSpinner size="18" />}
        </Button>
      </fetcher.Form>
    </Card>
  );
};
